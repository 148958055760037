export class EstimateByArea {
  constructor() {
    this._id = Date.now()

    this.loaded = false
    this.cover = null
    this.suburbs = null
    this.streetId = null
    this.streetLabel = null
    this.streetLat = null
    this.streetLng = null
  }

  static fromStreet(data) {
    const instance = new EstimateByArea()
    if (data.id) instance.streetId = data.id
    if (data.pureLabel) instance.streetLabel = data.pureLabel
    if (data.lat) instance.streetLat = data.lat
    if (data.lng) instance.streetLng = data.lng
    return instance
  }

  static fromSuburb(data) {
    const instance = new EstimateByArea()
    instance.suburbs = data
    return instance
  }

  fromDetail(data) {
    if (!data) return
    
    if (data._id) this._id = data._id
    if (data.cover) this.cover = data.cover
    if (data.suburbs) this.suburbs = data.suburbs
    if (data.streetId) this.streetId = data.streetId
    if (data.streetLabel) this.streetLabel = data.streetLabel
    if (data.streetLat) this.streetLat = data.streetLat
    if (data.streetLng) this.streetLng = data.streetLng
    this.loaded = true
  }
}
