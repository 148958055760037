'use client'

import { useCallback, useEffect, useRef, useState } from 'react'
import AutocompletePopup from './popup'
import classNames from 'classnames'
import { debounce, get, isFunction, size } from 'lodash-es'
import useFetch from '@hooks/useFetch'
import { useBoolean, useClickAway, useEvent, useNumber } from 'react-use'

export default function Autocomplete({
  url,
  getParams,
  fetcher,
  delay = 300,
  labelKey = 'label',
  onSelect,
  onBlur,
  onKeywordChange,
  disabled = false,
  className,
  inputClassName,
  placeholder
}) {

  const inputRef = useRef(null)
  const boxRef = useRef(null)
  useClickAway(boxRef, () => {
    hidePopup()
  })

  // 输入的文本
  const [text, setText] = useState('')
  const { result, loading } = useAutocompleteFetch({ url, getParams, fetcher, delay, text })
  // 当前选中的索引
  const [selectIdx, { inc: incIdx, dec: decIdx, set: setIdx, reset: resetIdx }] = useNumber(-1)
  // 是否显示弹出列表
  const [visiblePopup, toggleVisiblePopup] = useBoolean(false)
  // 显示弹出列表
  const displayPopup = useCallback(() => {
    toggleVisiblePopup(true)
  }, [toggleVisiblePopup])
  // 隐藏弹出列表
  const hidePopup = useCallback(() => {
    toggleVisiblePopup(false)
  }, [toggleVisiblePopup])
  function changeHandle(event) {
    setText(event.target.value)
  }
  function clickHandle() {
    if (size(result)) displayPopup()
  }

  useEffect(() => {
    if (loading) displayPopup()
  }, [loading, displayPopup])

  function selectHandle(item) {
    hidePopup()
    resetIdx()
    inputRef.current.value = get(item, labelKey)
    isFunction(onSelect) && onSelect(item)
  }

  useEvent('keydown', event => {
    const count = size(result)
    if (!count) return
    const { key } = event
    if (key === 'ArrowDown') {
      if ((selectIdx + 1) >= count) {
        setIdx(0)
      } else {
        incIdx()
      }
    } else if (key === 'ArrowUp') {
      if (selectIdx <= 0) {
        setIdx(count - 1)
      } else {
        decIdx()
      }
    } else if (key === 'Enter') {
      selectHandle(result[selectIdx])
      event.preventDefault()
    }
  }, inputRef.current)

  useEffect(() => {
    isFunction(onKeywordChange) && onKeywordChange(text)
  }, [text, onKeywordChange])

  return (
    <div ref={boxRef} className={className}>
      <AutocompletePopup show={visiblePopup} data={result} isLoading={loading} labelKey={labelKey} onSelect={selectHandle} selectIdx={selectIdx}>
        <input
          type='text'
          className={classNames(
            'border w-full h-48 px-16 text-base text-font disabled:bg-neutral-1 disabled:cursor-not-allowed',
            visiblePopup ? 'rounded-tl-sm rounded-tr-sm' : 'rounded-sm',
            inputClassName
          )}
          placeholder={placeholder}
          disabled={disabled}
          ref={inputRef}
          onBlur={onBlur}
          onChange={changeHandle}
          onClick={clickHandle} />
      </AutocompletePopup>
    </div>
  )
}

export function useAutocompleteFetch({
  url,
  getParams,
  fetcher,
  delay = 300,
  text
}) {
  const [result, setResult] = useState()
  const [loading, toggleLoading] = useBoolean(false)
  const [keywords, setKeywords] = useState('')
  const { data, isLoading } = useFetch(keywords && url && getParams ? { url, params: getParams(keywords) } : { url: null })

  useEffect(() => {
    if (!isFunction(fetcher) || !keywords) return
    toggleLoading(true)
    fetcher(keywords)
      .then(d => setResult(d))
      .finally(() => toggleLoading(false))
  }, [keywords, fetcher, toggleLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fn = useCallback(debounce(setKeywords, delay), [setKeywords, delay])
  const changeKeywords = useCallback(text => {
    fn(text)
  }, [fn])
  useEffect(() => {
    changeKeywords(text)
  }, [text, changeKeywords])

  useEffect(() => {
    toggleLoading(isLoading)
  }, [isLoading, toggleLoading])
  useEffect(() => {
    setResult(data)
  }, [data])

  return {
    result,
    loading
  }
}