'use client'

import classNames from 'classnames'
import { get, isFunction, map, size } from 'lodash-es'

export default function AutocompletePopup({
  children,
  show = false,
  data,
  isLoading,
  renderItem,
  onSelect,
  labelKey,
  selectIdx
}) {

  function clickHandle(item) {
    return (event) => {
      event.preventDefault()
      event.stopPropagation()
      isFunction(onSelect) && onSelect(item)
    }
  }

  return (
    <div className='relative'>
      {children}
      <div className={classNames(
        'absolute z-[1] inset-x-0 top-full bg-white border border-t-0 rounded-bl-sm rounded-br-sm',
        show ? 'animate-expansion' : 'hidden'
      )}>
        {
          isLoading && <Tip>Loading...</Tip>
        }
        {
          !isLoading && !size(data) && <Tip>Not Found Data</Tip>
        }
        {
          !isLoading &&
          map(data, (item, idx) => (
            isFunction(renderItem)
              ? renderItem(item, idx)
              : (
                <div
                  key={idx}
                  className={classNames(
                    'py-10 px-16 text-font text-sm cursor-pointer hover:bg-primary-light hover:text-primary childs-[mark]:hover:text-primary',
                    { '!text-primary !font-medium': selectIdx === idx }
                  )}
                  onClick={clickHandle(item)}
                >
                  <span className='line-clamp-1 child-[mark]:bg-transparent' dangerouslySetInnerHTML={{ __html: get(item, labelKey) || '' }}></span>
                </div>
              )
          ))
        }
      </div>
    </div>
  )
}

function Tip({
  children
}) {
  return <div className='py-40 text-center text-neutral-5 test-sm'>{children}</div>
}