import { first, get } from 'lodash-es'

export class EstimateByListing {
  constructor() {
    this._id = Date.now()

    this.loaded = false
    this.id = null
    this.slug = null
    this.cover = null
    this.address = null
    this.avmLow = null
    this.avmHigh = null
    this.bedrooms = null
    this.bathrooms = null
    this.carspaces = null
  }

  static fromData(data) {
    const instance = new EstimateByListing()
    if (data.id) instance.id = data.id
    if (data.slug) instance.slug = data.slug
    if (data.pureLabel) instance.address = data.pureLabel
    return instance
  }

  fromDetail(data) {
    if (!data) return

    if (data._id) this._id = data._id
    if (data.id) this.id = data.id
    if (data.slug) this.slug = data.slug
    if (data.images) this.cover = first(data.images)
    if (data.address) this.address = data.address
    if (data.avm) {
      this.avmLow = get(data, 'avm.low', null)
      this.avmHigh = get(data, 'avm.high', null)
    }
    if (data.bedrooms) this.bedrooms = data.bedrooms
    if (data.bathrooms) this.bathrooms = data.bathrooms
    if (data.carspaces) this.carspaces = data.carspaces
    this.loaded = true
  }
}
