'use client'

import classNames from 'classnames'
export default function CloseButton({
  className,
  iconClassName,
  iconSize = '!text-lg',
  circle = false,
  onClick
}) {

  return (
    <button
      type='button'
      className={
        classNames(
          'cursor-pointer w-32 h-32 flex items-center justify-center group',
          { 'rounded-half bg-white shadow-md border': circle },
          className
        )
      }
      onClick={onClick}
      data-noprogress
      aria-label='close button'
    >
      <i className={classNames('icon icon-close text-primary transition-transform pointer-events-none group-hover:rotate-90', { 'font-bold': !circle }, iconSize, iconClassName)}></i>
    </button>
  )
}