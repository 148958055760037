import { SelectLocationContext, useSelectLocationState } from './context'
import SelectLocationContent from './content'

export default function SelectLocation({
  show = false,
  oneSuburb = false,
  useRegion = true,
  useDistrict = true,
  useSuburb = true,
  onClose,
  onConfirm
}) {

  const state = useSelectLocationState(show)

  return (
    <SelectLocationContext.Provider value={{ ...state, oneSuburb, useRegion, useDistrict, useSuburb }}>
      <SelectLocationContent show={show} onClose={onClose} onConfirm={onConfirm}></SelectLocationContent>
    </SelectLocationContext.Provider>
  )
}