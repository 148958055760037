import httpClient from '@apiClient'
import { HOUSE_TYPE_ID } from '@config'
import { houseSearchType } from '@routerConfig'

/**
 * search buy
 * @param {string} keyword 
 * @returns data
 */
export async function fetchBuy(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    isMix: 1,
    key: keyword,
    typeId: typeId || [HOUSE_TYPE_ID.residential, HOUSE_TYPE_ID.rural].join(',')
  }
  const { locations = [], streets = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    streets,
    properties,
    surroundingSuburbs
  }
}

/**
 * search rent
 * @param {string} keyword 
 * @returns data
 */
export async function fetchRent(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    isMix: 1,
    key: keyword,
    typeId: typeId || HOUSE_TYPE_ID.rent
  }
  const { locations = [], streets = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    streets,
    properties,
    surroundingSuburbs
  }
}

/**
 * search sold
 * @param {string} keyword 
 * @returns data
 */
export async function fetchSold(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    isMix: 1,
    key: keyword,
    typeId: typeId || HOUSE_TYPE_ID.sold
  }
  const { locations = [], streets = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    streets,
    properties,
    surroundingSuburbs
  }
}

/**
 * search estimate
 * @param {string} keyword 
 * @returns data
 */
export async function fetchEstimate(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    key: keyword,
    typeId: typeId || HOUSE_TYPE_ID.estimate
  }
  const { locations = [], streets = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    streets,
    properties,
    surroundingSuburbs
  }
}

/**
 * search new homes
 * @param {string} keyword 
 * @returns data
 */
export async function fetchNewHomes(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    isProject: 1,
    key: keyword,
    typeId: typeId || HOUSE_TYPE_ID['new-homes']
  }
  const { locations = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    properties,
    surroundingSuburbs
  }
}

/**
 * search rural
 * @param {string} keyword 
 * @returns data
 */
export async function fetchRural(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    isMix: 1,
    key: keyword,
    typeId: typeId || HOUSE_TYPE_ID.rural
  }
  const { locations = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    properties,
    surroundingSuburbs
  }
}

/**
 * search commercial
 * @param {string} keyword 
 * @returns data
 */
export async function fetchCommercial(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    key: keyword,
    typeId: typeId || HOUSE_TYPE_ID['commercial-property-for-sale']
  }
  const { locations = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    properties,
    surroundingSuburbs
  }
}


/**
 * search businesses
 * @param {string} keyword 
 * @returns data
 */
export async function fetchBusinesses(keyword, typeId) {
  if (!keyword) return {}
  const params = {
    key: keyword,
    typeId: typeId || HOUSE_TYPE_ID['businesses-for-sale']
  }
  const { locations = [], properties = [], surroundingSuburbs = [] } = await httpClient.get('address/search', params)

  return {
    suburbs: locations,
    properties,
    surroundingSuburbs
  }
}

/**
 * search agent
 * @param {string} keyword 
 * @param {number} typeId 
 * @returns data
 */
export async function fetchAgent(keyword, typeId) {
  if (!keyword) return {}
  const { locations, offices, agents } = await httpClient.get('agent-promote', { key: keyword, typeId })
  return {
    suburbs: locations,
    offices,
    agents
  }
}


/**
 * search suburbs
 * @param {string} keyword 
 * @returns data
 */
export async function fetchSuburb(keyword) {
  if (!keyword) return {}
  const data = await httpClient.get('enum/suburbs', { key: keyword, profile: 1 })
  return {
    suburbs: data
  }
}

/**
 * auto fetch
 * @param {string} keyword 
 * @returns data
 */
export async function autoFetch(keyword, type) {
  switch (type) {
    case houseSearchType.residential:
    case HOUSE_TYPE_ID.residential:
      return fetchBuy(keyword)
    case houseSearchType.newHomes:
    case HOUSE_TYPE_ID['new-home']:
      return fetchNewHomes(keyword)
    case houseSearchType.rural:
    case HOUSE_TYPE_ID.rural:
      return fetchRural(keyword)
    case houseSearchType.commercialForSale:
    case houseSearchType.commercialForLease:
    case HOUSE_TYPE_ID['commercial-property-for-sale']:
    case HOUSE_TYPE_ID['commercial-property-for-lease']:
      return fetchCommercial(keyword)
    case houseSearchType.business:
    case HOUSE_TYPE_ID['businesses-for-sale']:
      return fetchBusinesses(keyword)
    case houseSearchType.sold:
    case HOUSE_TYPE_ID.sold:
      return fetchSold(keyword)
    case houseSearchType.rent:
    case HOUSE_TYPE_ID.rent:
      return fetchRent(keyword)
    default:
      return fetchBuy(keyword)
  }
}
