import { findIndex, isEqual, omit, take } from 'lodash-es'
import { BusinessesBySuburb } from './entity/businessesBySuburb'
import { BuyByListing } from './entity/buyByListing'
import { BuyBySuburb } from './entity/buyBySuburb'
import { CommercialBySuburb } from './entity/commercialBySuburb'
import { EstimateByListing } from './entity/estimateByListing'
import { NewHomesBySuburb } from './entity/newHomesBySuburb'
import { RentByListing } from './entity/rentByListing'
import { RentBySuburb } from './entity/rentBySuburb'
import { RuralBySuburb } from './entity/ruralBySuburb'
import { SoldBySuburb } from './entity/soldBySuburb'

const RECENT_VIEW_KEY = '__recentView__'

export const RecentType = {
  estimateByListing: 'estimateByListing',
  estimateByArea: 'estimateByArea',
  buyByListing: 'buyByListing',
  rentByListing: 'rentByListing',
  buyBySuburb: 'buyBySuburb',
  rentBySuburb: 'rentBySuburb',
  soldBySuburb: 'soldBySuburb',
  recommend: 'recommend',
  newHomesBySuburb: 'newHomesBySuburb',
  commercialForSaleBySuburb: 'commercialForSaleBySuburb',
  commercialForLeaseBySuburb: 'commercialForLeaseBySuburb',
  ruralBySuburb: 'ruralBySuburb',
  businessesBySuburb: 'businessesBySuburb',
}

export class RecentData {

  constructor(type, data) {
    this.type = type
    this.data = data
  }

  static createFromJSON(json) {
    return new RecentData(json.type, json.data)
  }

  static createEstimateByListing(data) {
    return new RecentData(RecentType.estimateByListing, EstimateByListing.fromData(data))
  }

  static createEstimateByArea(data) {
    return new RecentData(RecentType.estimateByArea, data)
  }

  static createBuyByListing(data) {
    return new RecentData(RecentType.buyByListing, BuyByListing.fromData(data))
  }

  static createRentByListing(data) {
    return new RecentData(RecentType.rentByListing, RentByListing.fromData(data))
  }

  static createBuyBySuburb(data) {
    return new RecentData(RecentType.buyBySuburb, BuyBySuburb.fromData(data))
  }

  static createRentBySuburb(data) {
    return new RecentData(RecentType.rentBySuburb, RentBySuburb.fromData(data))
  }

  static createSoldBySuburb(data) {
    return new RecentData(RecentType.soldBySuburb, SoldBySuburb.fromData(data))
  }

  static createNewHomesBySuburb(data) {
    return new RecentData(RecentType.newHomesBySuburb, NewHomesBySuburb.fromData(data))
  }

  static createCommercialForSaleBySuburb(data) {
    return new RecentData(RecentType.commercialForSaleBySuburb, CommercialBySuburb.fromData(data))
  }

  static createCommercialForLeaseBySuburb(data) {
    return new RecentData(RecentType.commercialForLeaseBySuburb, CommercialBySuburb.fromData(data))
  }

  static createRuRalBySuburb(data) {
    return new RecentData(RecentType.ruralBySuburb, RuralBySuburb.fromData(data))
  }

  static createBusinessesBySuburb(data) {
    return new RecentData(RecentType.businessesBySuburb, BusinessesBySuburb.fromData(data))
  }

  isEstimateByListing() {
    return this.type === RecentType.estimateByListing
  }

  isEstimateByArea() {
    return this.type === RecentType.estimateByArea
  }

  isBuyByListing() {
    return this.type === RecentType.buyByListing
  }

  isRentByListing() {
    return this.type === RecentType.rentByListing
  }

  isBuyBySuburb() {
    return this.type === RecentType.buyBySuburb
  }

  isRentBySuburb() {
    return this.type === RecentType.rentBySuburb
  }

  isSoldBySuburb() {
    return this.type === RecentType.soldBySuburb
  }

  isRecommend() {
    return this.type === RecentType.recommend
  }

  isNewHomesBySuburb() {
    return this.type === RecentType.newHomesBySuburb
  }

  isCommercialForSale() {
    return this.type === RecentType.commercialForSaleBySuburb
  }

  isCommercialForLease() {
    return this.type === RecentType.commercialForLeaseBySuburb
  }

  isRuralBySuburb() {
    return this.type === RecentType.ruralBySuburb
  }

  isBusinessesBySuburb() {
    return this.type === RecentType.businessesBySuburb
  }
}

export class RecentViewManager {
  static cacheEstimateByListing(data) {
    this.cache(RecentData.createEstimateByListing(data))
  }

  static cacheEstimateByArea(data) {
    this.cache(RecentData.createEstimateByArea(data))
  }

  static cacheBuyByListing(data) {
    this.cache(RecentData.createBuyByListing(data))
  }

  static cacheRentByListing(data) {
    this.cache(RecentData.createRentByListing(data))
  }

  static cacheBuyBySuburb(data) {
    this.cache(RecentData.createBuyBySuburb(data))
  }

  static cacheRentBySuburb(data) {
    this.cache(RecentData.createRentBySuburb(data))
  }

  static cacheSoldBySuburb(data) {
    this.cache(RecentData.createSoldBySuburb(data))
  }

  static cacheNewHomesBySuburb(data) {
    this.cache(RecentData.createNewHomesBySuburb(data))
  }

  static cacheCommercialForSaleBySuburb(data) {
    this.cache(RecentData.createCommercialForSaleBySuburb(data))
  }

  static cacheCommercialForLeaseBySuburb(data) {
    this.cache(RecentData.createCommercialForLeaseBySuburb(data))
  }

  static cacheRuralBySuburb(data) {
    this.cache(RecentData.createRuRalBySuburb(data))
  }

  static cacheBusinessesBySuburb(data) {
    this.cache(RecentData.createBusinessesBySuburb(data))
  }

  static cache(data) {
    const list = this.getCacheList()
    const index = list.findIndex(item => {
      return (
        item.type === data.type && (
          (item.data._id === data.data._id) ||
          (item.data.id && item.data.id === data.data.id) ||
          (item.id && item.id === data.id) ||
          (isEqual(omit(data.data, ['_id', 'loaded', 'cover']), omit(item.data, ['_id', 'loaded', 'cover'])))
        )
      )
    })
    if (index !== -1) {
      list.splice(index, 1)
    }
    list.unshift(data)
    localStorage.setItem(RECENT_VIEW_KEY, JSON.stringify(list))
  }

  static getCacheList() {
    if (typeof localStorage === 'undefined') {
      return []
    }

    const list = localStorage.getItem(RECENT_VIEW_KEY)
    if (list) {
      return take(JSON.parse(list), 10)
    }

    return []
  }

  static updateCacheData(data) {
    const list = this.getCacheList()
    const index = findIndex(list, item => (
      item.type === data.type && (
        (item.data._id === data.data._id) ||
        (item.id && item.id === data.id)
      )
    ))
    if (index !== -1) {
      list[index] = data
      localStorage.setItem(RECENT_VIEW_KEY, JSON.stringify(list))
    }
  }
}
