import { assign, get } from 'lodash-es'
import { pushDataLayer, pushNextPageDataLayer } from '../index'
import { NEW_HOMES_ALIAS } from '@homeSearch/modules/filter/config/content'

export const homeDataLayer = {
  recentClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'recent_activity'
    })
  },

  savedSearchClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'saved_searches'
    })
  },

  searchClick(searchType) {

    homeSearchView()

    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'show_properties'
    })

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': get(searchType, 'alias', 'buy')
    })

    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'search_cta'
    })

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': 'homepage'
    })

  }
}

export const findAnAgentDataLayer = {
  searchClick() {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'search_cta'
    })
    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': 'agent'
    })
  }
}

export const commercialDataLayer = {
  searchClick(searchType) {

    homeSearchView()

    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'show_properties'
    })

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': get(searchType, 'alias', 'commercial')
    })

    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'search_cta'
    })

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': 'commercial'
    })
  }
}

export const newHomesDataLayer = {
  searchClick(searchType) {
    homeSearchView()

    if (get(searchType, 'alias') === NEW_HOMES_ALIAS.builders) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'Search',
        'event_interaction': 'CTA',
        'event_type': 'Home Builders'
      })
    }

    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'search_cta'
    })

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': 'new home'
    })
  }
}

export const ruralDataLayer = {
  searchClick() {
    homeSearchView()

    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'search_cta'
    })

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': 'Rural'
    })
  }
}

export const searchFilterDataLayer = {
  rowClick(verticalType) {
    pushDataLayer({
      'event': 'ga4_event',
      'event_name': 'perform_search',
      'search_method': 'select_from_dropdown'
    })

    pushDataLayer({
      'event': 'analytics_track',
      'event_journey': 'Search',
      'event_interaction': 'CTA',
      'event_type': verticalType
    })
  }
}

export const selectLocationDataLayer = {
  rowClick({ region, district, suburb }) {
    if (region && district && suburb) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'search',
        'event_interaction': 'select location',
        'event_type': 'step 3 - suburb',
        'selected_region': region.label,
        'selected_district': district.label,
        'selected_suburb': suburb.label
      })
    } else if (region && district) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'search',
        'event_interaction': 'select location',
        'event_type': 'step 2 - district',
        'selected_region': region.label,
        'selected_district': district.label
      })
    } else if (region) {
      pushDataLayer({
        'event': 'analytics_track',
        'event_journey': 'search',
        'event_interaction': 'select location',
        'event_type': 'step 1 - region',
        'selected_region': region.label
      })
    }
  }
}

function homeSearchView() {
  const keyword = localStorage.getItem('or-home-search-keyword')
  const isSelectSuburb = localStorage.getItem('or-home-search-select-suburb')
  localStorage.removeItem('or-home-search-keyword')
  localStorage.removeItem('or-home-search-select-suburb')

  pushNextPageDataLayer(assign(
    {
      'search_method': isSelectSuburb ? 'select location from list' : 'typed'
    },
    isSelectSuburb ? undefined : { 'search_query': keyword }
  ))
}