import { joinStr } from '@utils'
import { first } from 'lodash-es'

export class RentByListing {
  constructor() {
    this._id = Date.now()

    this.loaded = false
    this.id = null
    this.slug = null
    this.cover = null
    this.teaser = null
    this.address = null
    this.bedrooms = null
    this.bathrooms = null
    this.carspaces = null
    this.saleMethod = null
  }

  static fromData(data) {
    const instance = new RentByListing()
    if (data.id) instance.id = data.id
    if (data.slug) instance.slug = data.slug
    if (data.pureLabel) instance.address = data.pureLabel
    return instance
  }

  fromDetail(data) {
    if (!data) return

    if (data._id) this._id = data._id
    if (data.id) this.id = data.id
    if (data.slug) this.slug = data.slug
    if (data.images) this.cover = first(data.images)
    if (data.teaser) this.teaser = data.teaser
    if (data.address) this.address = data.address
    if (data.bedrooms) this.bedrooms = data.bedrooms
    if (data.bathrooms) this.bathrooms = data.bathrooms
    if (data.carspaces) this.carspaces = data.carspaces
    if (data.priceBold || data.priceLight) this.saleMethod = joinStr([data.priceBold, data.priceLight], ' ')
    this.loaded = true
  }
}
