import classNames from 'classnames'
import FullPopup from './index'
import MaskLoading from '@component/loading/mask'
import AppendRoot from '@component/appendRoot'
import CloseButton from '@component/closeButton'

export function FilterPopup({
  show,
  title,
  onBack,
  onClose,
  children,
  innerClassName,
  buttonWrapClassName,
  cancelButton,
  confirmButton,
  loading = false
}) {

  const showBtn = (cancelButton || confirmButton)
  return (
    <AppendRoot>
      <FullPopup top show={show} smCenter maskCloseable={false} contentClassName='!bg-transparent sm:px-10 sm:max-h-full'>
        <div className='w-screen h-fill-screen sm:w-[800px] sm:h-[640px] sm:max-h-fill-screen sm:rounded-xl flex flex-col max-w-full mx-auto bg-white'>
          <div className='flex items-center p-16 sm:px-38 sm:pt-28 sm:pb-24'>
            {
              onBack && <IconButton icon='icon-back' iconClassName='!text-1xl text-primary' onClick={onBack}></IconButton>
            }
            <span className='flex-1 text-center text-lg font-bold'>{title}</span>
            {
              onClose && (
                <CloseButton iconClassName='!text-xl' onClick={onClose}></CloseButton>
              )
            }
          </div>
          <div className={classNames(
            'relative flex-1 min-h-0 overflow-auto px-16 sm:px-38',
            { 'min-h-[400px]': loading },
            showBtn ? 'pb-30' : 'mb-30',
            innerClassName
          )}>
            {children}
            <MaskLoading loading={loading}></MaskLoading>
          </div>
          {
            showBtn && (
              <div className={classNames('py-16 px-38 gap-x-16 flex shadow-[0_-10px_36px_rgba(0,0,0,0.06)]', buttonWrapClassName)}>
                {cancelButton}
                {confirmButton}
              </div>
            )
          }
        </div>
      </FullPopup>
    </AppendRoot>
  )
}

function IconButton({
  onClick,
  icon,
  children,
  className,
  iconClassName
}) {
  return (
    <div className={classNames('cursor-pointer', className)} onClick={onClick}>
      <span className='text-base'>{children}</span>
      <i className={classNames('icon align-middle inline-block sm:font-bold', icon, iconClassName)}></i>
    </div>
  )
}