import { stopPropagation } from '@utils'
import classNames from 'classnames'
import { isFunction } from 'lodash-es'

export default function SwitchContent({
  checked,
  onChange,
  trueValue = true,
  falseValse = false,
  ariaLabel = 'checkbox',
  id
}) {

  function changeHandle(event) {
    isFunction(onChange) && onChange(event.target.checked ? trueValue : falseValse, event)
  }

  return (
    <label
      className={classNames('inline-flex items-center cursor-pointer relative w-48 h-24 px-4 rounded-xl transition-colors', checked ? 'bg-primary flex-row-reverse' : 'bg-neutral-3 flex-row')}
      onClick={stopPropagation}
    >
      <span className='w-16 h-16 rounded-half bg-white flex justify-center items-center'>
        <i className={classNames('icon !text-xs font-bold', checked ? 'icon-correct' : 'icon-close text-neutral-3')}></i>
      </span>
      <input
        type='checkbox'
        checked={checked}
        onChange={changeHandle}
        className='absolute outline-none m-0 w-0 h-0 -left-[999px]'
        aria-labelledby={ariaLabel}
        id={id}
      />
    </label>
  )
}