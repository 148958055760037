'use client'

import CheckboxContent from '@component/form/checkbox/content'
import { FilterPopup } from '@component/fullPopup/popup'
import { selectLocationDataLayer } from '@plugin/google/gtm/modules/home'
import { joinStr } from '@utils'
import classNames from 'classnames'
import { concat, each, filter, find, first, includes, isFunction, map, size, some } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useSelectLocationContext } from './context'

export default function SelectLocationContent({
  show,
  onClose,
  onConfirm
}) {

  const {
    isLoading,
    viewData,
    backHandle,
    cleanSelect,
    selectRegions,
    selectDistricts,
    selectSuburbs,
    isRegionLevel,
    isDistrictLevel,
    isSuburbLevel,
    cacheData,
    useRegion,
    useDistrict,
    useSuburb
  } = useSelectLocationContext()

  const title = useMemo(() => {
    if (isRegionLevel) {
      return 'Select a region'
    } else if (isDistrictLevel) {
      return 'Select a district'
    } else if (isSuburbLevel) {
      return 'Select a suburb'
    }
  }, [isRegionLevel, isDistrictLevel, isSuburbLevel])

  const onBack = useCallback(() => {
    if (!backHandle()) {
      isFunction(onClose) && onClose()
    }
  }, [onClose, backHandle])
  const confirmHandle = useCallback(() => {
    // 判断是否选择了region下面的所有district
    const districts = combineData(selectDistricts, cacheData)
    const suburbs = combineData(selectSuburbs, cacheData)

    const res = map(
      concat(selectRegions, districts, suburbs),
      item => {
        return { ...item, label: item.combineLabel || item.label }
      }
    )
    isFunction(onConfirm) && onConfirm(res)
  }, [onConfirm, selectRegions, selectDistricts, selectSuburbs, cacheData])
  return show && (
    <FilterPopup
      title={title}
      loading={isLoading}
      show={show}
      onBack={onBack}
      onClose={onClose}
      innerClassName='space-y-10'
      {...((isRegionLevel && useRegion) || (isDistrictLevel && useDistrict) || (isSuburbLevel && useSuburb)) ? {
        cancelButton: <button type='button' className='button-primary-out flex-1' onClick={cleanSelect}>Clear</button>,
        confirmButton: <button type='button' className='button-orange flex-[2_2_0]' onClick={confirmHandle}>Refine search</button>
      } : {}}
    >
      <div className='py-10 -mx-6'>
        <SelectAll></SelectAll>
        {
          map(viewData, item => <SuburbRow key={item.id} data={item}></SuburbRow>)
        }
      </div>
    </FilterPopup>
  )
}

function SuburbRow({
  data
}) {

  const {
    region,
    district,
    selectRegions,
    toggleRegion,
    selectDistricts,
    toggleDistrict,
    selectSuburbs,
    toggleSuburb,
    openRegion,
    openDistrict,
    oneSuburb,
    useRegion,
    useDistrict,
    useSuburb,
    isRegionLevel,
    isDistrictLevel,
    isSuburbLevel
  } = useSelectLocationContext()
  const checked = useMemo(() => {
    if (district) {
      return !!find(selectSuburbs, data)
    } else if (region) {
      return !!find(selectDistricts, data)
    } else {
      return !!find(selectRegions, data)
    }
  }, [data, district, region, selectRegions, selectDistricts, selectSuburbs])
  const childrenSelectNum = useMemo(() => {
    let res = 0
    if (data.level === 'region') {
      res = size(filter(selectDistricts, item => (item.regionId === data.id)))
    } else if (data.level === 'district') {
      const ids = map(selectSuburbs, item => item.id)
      res = size(filter(data.children, item => ids.includes(item.id)))
    }
    return res
  }, [data, selectDistricts, selectSuburbs])
  const changeHandle = useCallback(() => {
    if (isSuburbLevel) {
      data.combineLabel = joinStr([data.label, district.label], ', ')
      toggleSuburb(data, oneSuburb)
      selectLocationDataLayer.rowClick({ region, district, suburb: data })
    } else if (isDistrictLevel) {
      data.combineLabel = joinStr([data.label, region.label], ', ')
      toggleDistrict(data, oneSuburb)
      selectLocationDataLayer.rowClick({ region, district: data })
    } else {
      toggleRegion(data, oneSuburb)
      selectLocationDataLayer.rowClick({ region: data })
    }
  }, [toggleRegion, toggleDistrict, toggleSuburb, data, region, district, oneSuburb, isDistrictLevel, isSuburbLevel])
  function clickHandle() {
    if (data.level === 'region') {
      openRegion(data)
      selectLocationDataLayer.rowClick({ region: data })
    } else if (data.level === 'district') {
      openDistrict(data)
      selectLocationDataLayer.rowClick({ region, district: data })
    } else {
      changeHandle()
    }
  }

  return (
    <div className='flex items-center justify-between hover:bg-neutral-1 py-6 px-6'>
      {
        (
          (isRegionLevel && useRegion) ||
          (isDistrictLevel && useDistrict) ||
          (isSuburbLevel && useSuburb)
        ) && (
          <CheckboxContent dotClassName='!border-neutral-3' checked={checked} onChange={changeHandle}></CheckboxContent>
        )
      }
      <div className={classNames('text-sm flex-1 cursor-pointer', childrenSelectNum ? 'text-secondary' : 'text-neutral-5')} onClick={clickHandle}>
        {data.label}
        {childrenSelectNum ? `(${childrenSelectNum})` : undefined}
        {size(data.children) > 0 || isRegionLevel ? <i className='icon icon-arrow-right !text-xs self-center ml-2' /> : undefined}
      </div>
      {
        ((isRegionLevel && useRegion) || (isDistrictLevel && useDistrict)) &&
        data.houseCount > 0 &&
        (<span className='text-sm text-neutral-3 ml-6'>{data.houseCount}</span>)
      }
    </div>
  )
}

function SelectAll() {

  const {
    useRegion,
    useDistrict,
    useSuburb,
    selectRegions,
    selectDistricts,
    selectSuburbs,
    viewData,
    toggleAllRegion,
    toggleAllDistrict,
    toggleAllSuburb,
    oneSuburb,
    isRegionLevel,
    isDistrictLevel,
    isSuburbLevel
  } = useSelectLocationContext()


  const checked = useMemo(() => {
    return (
      isRegionLevel && size(selectRegions) > 0 && !some(viewData, item => !includes(selectRegions, item)) ||
      isDistrictLevel && size(selectDistricts) > 0 && !some(viewData, item => !includes(selectDistricts, item)) ||
      isSuburbLevel && size(selectSuburbs) > 0 && !some(viewData, item => !includes(selectSuburbs, item))
    )
  }, [selectRegions, selectDistricts, selectSuburbs, viewData, isRegionLevel, isDistrictLevel, isSuburbLevel])

  const changeHandle = useCallback(() => {
    if (isRegionLevel) {
      toggleAllRegion()
    } else if (isDistrictLevel) {
      toggleAllDistrict()
    } else if (isSuburbLevel) {
      toggleAllSuburb()
    }
  }, [toggleAllRegion, toggleAllDistrict, toggleAllSuburb, isRegionLevel, isDistrictLevel, isSuburbLevel])

  const visible = (
    (isRegionLevel && useRegion) ||
    (isDistrictLevel && useDistrict) ||
    (isSuburbLevel && useSuburb)
  )

  if (!visible || oneSuburb) return null

  return (
    <div className='flex items-center justify-between hover:bg-neutral-1 py-6 px-6'>
      {
        (
          (isRegionLevel && useRegion) ||
          (isDistrictLevel && useDistrict) ||
          (isSuburbLevel && useSuburb)
        ) && (
          <CheckboxContent dotClassName='!border-neutral-3' checked={checked} onChange={changeHandle}></CheckboxContent>
        )
      }
      <div className='text-sm flex-1 cursor-pointer text-neutral-5' onClick={changeHandle}>Select All</div>
    </div>
  )
}

// 判断是否选择了region下面的所有district
function combineData(selected, cacheData) {
  if (size(selected) === 0) return selected

  const res = []
  each(cacheData.district, list => {
    each(list, district => {
      const temp = []
      each(district.children, item => {
        const exit = includes(selected, item)
        if (exit) {
          temp.push(item)
        }
      })
      const hasAll = size(temp) === size(district.children)
      if (hasAll) {
        res.push(district)
      } else {
        res.push(...temp)
      }
    })
  })

  each(cacheData.district, list => {
    const temp = []
    each(list, item => {
      const exit = includes(selected, item)
      if (exit) {
        temp.push(item)
      }
    })
    const hasAll = size(temp) === size(list)
    if (hasAll) {
      const region = find(cacheData.region, item => item.id === first(temp).regionId)
      res.push(region)
    } else {
      res.push(...temp)
    }
  })

  return res
}