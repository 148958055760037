import useFetch from '@hooks/useFetch'
import { concat, filter, findIndex, first, includes, size, some, uniq } from 'lodash-es'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { useList } from 'react-use'

export const SelectLocationContext = createContext()
export function useSelectLocationContext() {
  return useContext(SelectLocationContext)
}

export function useSelectLocationState(show) {

  const [region, setRegion] = useState(null)
  const [district, setDistrict] = useState(null)
  const [cacheData, setCacheData] = useState({})
  const fetchUrl = useMemo(() => {
    if (!show) return null

    let res = 'enum/regions'
    if (region) {
      res = `enum/region/${region.id}`
    }
    return res
  }, [region, show])

  const { data, isLoading } = useFetch(fetchUrl, {
    onSuccess: values => {
      const level = first(values).level
      if (level === 'region') {
        setCacheData(d => ({ ...d, region: values }))
      } else if (level === 'district') {
        setCacheData(d => ({ ...d, district: { ...d.district, [first(values).regionId]: values } }))
      }
    }
  })
  const viewData = useMemo(() => district ? district.children : data, [data, district])

  // 当前已选中的regions
  const [selectRegions, { push: pushSelectRegion, removeAt: removeAtSelectRegion, set: setSelectRegions }] = useList([])
  // 当前已选中的district
  const [selectDistricts, { push: pushSelectDistrict, removeAt: removeAtSelectDistrict, set: setSelectDistrict }] = useList([])
  // 当前已选中的suburb
  const [selectSuburbs, { push: pushSelectSuburb, removeAt: removeAtSelectSuburb, set: setSelectSuburb }] = useList([])
  // 当前是哪一级
  const [isRegionLevel, isDistrictLevel, isSuburbLevel] = useMemo(() => {
    return [
      !district && !region,
      !district && !!region,
      !!district && !!region,
    ]
  }, [district, region])
  // 清除所有选择
  const cleanSelect = useCallback(() => {
    setSelectRegions([])
    setSelectDistrict([])
    setSelectSuburb([])
  }, [setSelectRegions, setSelectDistrict, setSelectSuburb])
  // 切换选择region
  const toggleRegion = useCallback((data, oneSuburb) => {
    const idx = findIndex(selectRegions, data)
    if (oneSuburb) cleanSelect()
    if (idx === -1) {
      pushSelectRegion(data)
    } else {
      removeAtSelectRegion(idx)
    }
  }, [selectRegions, pushSelectRegion, removeAtSelectRegion, cleanSelect])
  // 切换选择district
  const toggleDistrict = useCallback((data, oneSuburb) => {
    const idx = findIndex(selectDistricts, data)
    if (oneSuburb) cleanSelect()
    if (idx === -1) {
      pushSelectDistrict(data)
    } else {
      removeAtSelectDistrict(idx)
    }
  }, [selectDistricts, pushSelectDistrict, removeAtSelectDistrict, cleanSelect])
  // 切换选择suburb
  const toggleSuburb = useCallback((data, oneSuburb) => {
    const idx = findIndex(selectSuburbs, data)
    if (oneSuburb) cleanSelect()
    if (idx === -1) {
      pushSelectSuburb(data)
    } else {
      removeAtSelectSuburb(idx)
    }
  }, [selectSuburbs, pushSelectSuburb, removeAtSelectSuburb, cleanSelect])
  // 选中一个region
  const openRegion = useCallback(d => {
    setRegion(d)
  }, [setRegion])
  // 选中一个district
  const openDistrict = useCallback(d => {
    setDistrict(d)
  }, [setDistrict])
  // 返回上一级
  const backHandle = useCallback(() => {
    if (district) {
      setDistrict()
      return true
    } else if (region) {
      setRegion()
      return true
    }
    return false
  }, [region, district, setRegion, setDistrict])

  // 切换选中所有region
  const toggleAllRegion = useCallback(() => {
    if (size(selectRegions) > 0) {
      setSelectRegions([])
    } else {
      setSelectRegions(viewData)
    }
  }, [setSelectRegions, viewData, selectRegions])

  // 切换选中所有district
  const toggleAllDistrict = useCallback(() => {
    const notAll = some(viewData, item => !includes(selectDistricts, item))
    if (notAll) {
      const d = uniq(concat(selectDistricts, viewData))
      setSelectDistrict(d)
    } else {
      setSelectDistrict(filter(selectDistricts, item => item.regionId !== region.id))
    }
  }, [setSelectDistrict, viewData, selectDistricts, region])

  // 切换选中所有suburb
  const toggleAllSuburb = useCallback(() => {
    const notAll = some(viewData, item => !includes(selectSuburbs, item))
    if (notAll) {
      const d = uniq(concat(selectSuburbs, viewData))
      setSelectSuburb(d)
    } else {
      setSelectSuburb(filter(selectSuburbs, item => item.districtId !== district.id))
    }
  }, [setSelectSuburb, viewData, selectSuburbs, district])

  return {
    region,
    district,
    viewData,
    isLoading,
    selectRegions,
    selectDistricts,
    selectSuburbs,
    isRegionLevel,
    isDistrictLevel,
    isSuburbLevel,
    cacheData,
    toggleRegion,
    toggleDistrict,
    toggleSuburb,
    openRegion,
    openDistrict,
    backHandle,
    cleanSelect,
    toggleAllRegion,
    toggleAllDistrict,
    toggleAllSuburb,
  }
}
