'use client'

import { useAutocompleteFetch } from '@component/autocomplete'
import SwitchContent from '@component/form/switch/content'
import IconLoading from '@icon/const/loading.svg?url'
import IconHand from '@icon/home/hand.svg?url'
import IconSuburb from '@icon/home/type-suburb.svg?url'
import { searchFilterDataLayer } from '@plugin/google/gtm/modules/home'
import { RecentViewManager } from '@plugin/recentView'
import { BuyByListing } from '@plugin/recentView/entity/buyByListing'
import { EstimateByArea } from '@plugin/recentView/entity/estimateByArea'
import { EstimateByListing } from '@plugin/recentView/entity/estimateByListing'
import { RentByListing } from '@plugin/recentView/entity/rentByListing'
import { agencyDetail, agentDetail, houseDetail } from '@router'
import { isEstimateData, isRentData, isSoldData } from '@utils/house'
import classNames from 'classnames'
import { capitalize, concat, filter, findIndex, flatten, get, includes, isEqual, map, size, split } from 'lodash-es'
import Image from 'next/image'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { useCallback, useEffect, useMemo } from 'react'
import useHomeSearchContext, { ITEM_TYPE, pertainType } from '../context'
import './index.scss'
import Suggested from './suggested'

export default function HomeSearchPopup({
  fetcher,
  showAgency = false,
  showRegion = true,
  showDistrict = true,
  showSuburb = true,
  showSuggested = true,
  showSurround = true,
  className
}) {

  const {
    keyword,
    displayPopup,
    setDataList,
    showPopup,
    surrounding,
    toggleSurrounding,
    showSelectLocation
  } = useHomeSearchContext()
  // fetch
  const { result, loading } = useAutocompleteFetch({ fetcher, text: keyword })
  // 各种数据合集
  const data = useMemo(() => {
    return [
      pertainType(get(result, 'suburbs', []), ITEM_TYPE.suburb),
      pertainType(get(showAgency ? result : {}, 'offices', []), ITEM_TYPE.office),
      pertainType(get(result, 'agents', []), ITEM_TYPE.agent),
      pertainType(get(result, 'streets', []), ITEM_TYPE.street),
      pertainType(get(result, 'properties', []), ITEM_TYPE.property),
      pertainType(get(result, 'surroundingSuburbs', []), ITEM_TYPE.suburb),
    ]
  }, [result, showAgency])
  const [
    suburbs,
    offices,
    agents,
    streets,
    properties,
    surroundingSuburbs
  ] = useMemo(() => data, [data])

  // 正在显示的数据列表
  const dataList = useMemo(() => flatten(data), [data])
  useEffect(() => {
    setDataList(dataList)
  }, [dataList, setDataList])
  // suburbs和streets合并
  const combineSbSt = useMemo(() => {
    const list = filter(suburbs, item => (
      (showRegion && item.level === 'region') ||
      (showDistrict && item.level === 'district') ||
      (showSuburb && item.level === 'suburb')
    ))
    return concat(list, streets)
  }, [suburbs, streets, showRegion, showDistrict, showSuburb])

  useEffect(() => {
    if (loading && keyword) showPopup()
  }, [loading, showPopup, keyword])

  return (
    <div className={classNames('absolute z-50 inset-x-0 top-full py-16 bg-white origin-top border border-t-0 rounded-b-sm', displayPopup ? 'animate-expansion' : 'hidden', className)}>
      {
        loading && <Loading></Loading>
      }
      {
        !loading && (
          <>
            <div className='-mt-16'>
              {size(combineSbSt) > 0 && <PopupGroup title='Location' data={combineSbSt} suburb></PopupGroup>}
              {size(properties) > 0 && <PopupGroup title='Properties' data={properties} property></PopupGroup>}
              {size(offices) > 0 && <PopupGroup title='Agency' data={offices} office></PopupGroup>}
              {size(agents) > 0 && <PopupGroup title='Agent' data={agents} agent></PopupGroup>}
            </div>
            <div className='py-8 -my-16'>
              <Line icon={IconHand} onClick={showSelectLocation}>Select location from list</Line>
              {
                showSurround && (
                  <Line
                    icon={IconSuburb}
                    right={<SwitchContent checked={surrounding} onChange={toggleSurrounding} ariaLabel='include surrounding suburbs'></SwitchContent>}
                    onClick={toggleSurrounding}
                  >
                    Include surrounding suburbs
                  </Line>
                )
              }
              {displayPopup && showSuggested && <Suggested priorityData={surroundingSuburbs}></Suggested>}
            </div>
          </>
        )
      }
    </div>
  )
}

export function PopupGroup({
  title,
  data,
  suburb = false,
  property = false,
  office = false,
  agent = false,
  showLevel = true,
  className
}) {

  return (
    <div className={classNames('py-16 border-t first:border-t-0', className)}>
      {title && <div className='px-16 text-font text-base'>{title}</div>}
      {data && (
        <div className='space-y-4 mt-6'>
          {
            map(data, (item, idx) => (
              <Row data={item} key={[item.id, idx].join('-')}>
                {suburb && (
                  item.dataType === ITEM_TYPE.street
                    ? <StreetRow data={item}></StreetRow>
                    : <SuburbRow data={item} showLevel={showLevel}></SuburbRow>
                )}
                {property && <PropertyRow data={item}></PropertyRow>}
                {office && <OfficeRow data={item}></OfficeRow>}
                {agent && <AgentRow data={item}></AgentRow>}
              </Row>
            ))
          }
        </div>
      )}
    </div>
  )
}

function Line({
  icon,
  right,
  children,
  onClick
}) {

  return (
    <div className='px-16 py-8 cursor-pointer flex items-center' onClick={onClick}>
      <div className='flex items-center flex-1'>
        <Image src={icon} width={24} height={24} alt='icon' unoptimized></Image>
        <span className='text-font text-base ml-10'>{children}</span>
      </div>
      {right}
    </div>
  )
}

function Row({
  children,
  data
}) {

  const { currentSelect, dataList, setSelectIdx, selectItem } = useHomeSearchContext()
  const isActive = isEqual(data, currentSelect)
  const hoverHandle = useCallback(() => {
    setSelectIdx(findIndex(dataList, data))
  }, [dataList, data, setSelectIdx])
  const leaveHandle = useCallback(() => {
    setSelectIdx(-1)
  }, [setSelectIdx])
  const clickHandle = useCallback(() => {
    if ([
      ITEM_TYPE.agent,
      ITEM_TYPE.office,
      ITEM_TYPE.listing
    ].includes(data.dataType)) return
    selectItem(data)
  }, [data, selectItem])

  return (
    <div className={classNames('cursor-pointer search-row', { 'active': isActive })} onMouseEnter={hoverHandle} onMouseLeave={leaveHandle} onClick={clickHandle}>
      {children}
    </div>
  )
}

function SuburbRow({
  data,
  showLevel = true
}) {

  return (
    <div className='px-16 py-6'>
      <div className='flex items-center justify-between text-sm'>
        <span className='text-neutral-5' dangerouslySetInnerHTML={{ __html: data.label || '' }}></span>
        {!!data.houseCount && <span>{data.houseCount}</span>}
      </div>
      {
        showLevel && (
          <div className='text-font text-xs italic mt-2'>
            <span>{capitalize(data.level)}</span>
          </div>
        )
      }
    </div>
  )
}

function StreetRow({
  data
}) {

  const clickHandle = useCallback(() => {
    RecentViewManager.cacheEstimateByArea(
      EstimateByArea.fromStreet(data)
    )
    searchFilterDataLayer.rowClick('valuation')
  }, [data])
  return (
    <div className='px-16 py-6 flex items-center justify-between' onClick={clickHandle}>
      <span className='text-neutral-5 text-sm' dangerouslySetInnerHTML={{ __html: data.label || '' }}></span>
      <span className='italic text-xs'>{capitalize(data.level)}</span>
    </div>
  )
}

function PropertyRow({
  data
}) {

  const clickHandle = useCallback(() => {
    data
    const isListings = includes(data.level, 'listing')
    const isEstimate = includes(data.level, 'property')
    const isRent = includes(data.level, 'rent')

    if (isListings) {
      RecentViewManager.cacheBuyByListing(
        BuyByListing.fromData(data)
      )
    } else if (isRent) {
      RecentViewManager.cacheRentByListing(
        RentByListing.fromData(data)
      )
    } else if (isEstimate) {
      RecentViewManager.cacheEstimateByListing(
        EstimateByListing.fromData(data)
      )
    }
    searchFilterDataLayer.rowClick(isSoldData(data) ? 'sold' : isEstimateData(data) ? 'valuation' : isRentData(data) ? 'rent' : 'buy')
  }, [data])
  return (
    <DisablePrefetchLink
      href={houseDetail(data)}
      className='px-16 py-6 flex flex-col items-start gap-y-4 gap-x-10 justify-between sm:flex-row sm:items-center'
      onClick={clickHandle}
    >
      <span className='text-neutral-5 text-sm flex-1 line-clamp-1' dangerouslySetInnerHTML={{ __html: data.label || '' }}></span>
      <div className='flex gap-x-8'>
        {
          map(split(data.level, ','), (item, idx) => (
            <PropertyDot
              key={idx}
              listing={item === 'listing'}
              sold={item === 'recentlySold'}
              estimate={item === 'property'}
              rent={item === 'rent'}
            ></PropertyDot>
          ))
        }
      </div>
    </DisablePrefetchLink>
  )
}
function PropertyDot({
  listing = false,
  sold = false,
  estimate = false,
  rent = false
}) {

  return (listing || sold || estimate || rent) && (
    <span className={classNames(
      'py-2 pl-8 pr-12 border bg-neutral-1 text-font text-xs inline-flex items-center rounded-4xl before:inline-block before:mr-8 before:w-6 before:h-6 before:rounded-half sm:text-sm sm:py-3',
      { 'before:bg-red': sold },
      { 'before:bg-rural': estimate },
      { 'before:bg-secondary': listing },
      { 'before:bg-rural': rent },
    )}>
      {listing && 'For Sale'}
      {sold && 'Recently Sold'}
      {estimate && 'Estimate'}
      {rent && 'Rent'}
    </span>
  )
}

function OfficeRow({
  data
}) {
  return (
    <DisablePrefetchLink href={agencyDetail(data)} className='px-16 py-6 text-neutral-5 text-sm block' onClick={() => searchFilterDataLayer.rowClick('office')}>
      <span>{data.name}</span>
    </DisablePrefetchLink>
  )
}

function AgentRow({
  data
}) {
  return (
    <DisablePrefetchLink href={agentDetail(data)} className='px-16 py-6 text-neutral-5 text-sm block' onClick={() => searchFilterDataLayer.rowClick('agent')}>
      <span>{data.name}</span>
    </DisablePrefetchLink>
  )
}

function Loading() {
  return (
    <div className='pt-24 pb-50 text-center'>
      <Image src={IconLoading} alt='loading' width={26} height={26} unoptimized></Image>
      <div className='mt-8 text-font text-sm'>Loading search results</div>
    </div>
  )
}
