export class BusinessesBySuburb {
  constructor() {
    this._id = Date.now()

    this.loaded = false
    this.cover = null
    this.suburbs = null
    this.surrounding = false
    this.priceFrom = null
    this.priceTo = null
    this.bedrooms = null
    this.bathrooms = null
    this.carspaces = null
    this.categories = null
    this.landarea = null
    this.floorarea = null
    this.filter = null
    this.saved = false
  }

  static fromData(data) {
    const instance = new BusinessesBySuburb()
    if (data.suburbs) instance.suburbs = data.suburbs
    if (data.surrounding) instance.surrounding = data.surrounding
    if (data.priceFrom) instance.priceFrom = data.priceFrom
    if (data.priceTo) instance.priceTo = data.priceTo
    if (data.bedrooms) instance.bedrooms = data.bedrooms
    if (data.bathrooms) instance.bathrooms = data.bathrooms
    if (data.carspaces) instance.carspaces = data.carspaces
    if (data.categories) instance.categories = data.categories
    if (data.landarea) instance.landarea = data.landarea
    if (data.floorarea) instance.floorarea = data.floorarea
    if (data.filter) instance.filter = data.filter
    if (data.saved) instance.saved = data.saved
    return instance
  }

  fromDetail(data) {
    if (!data) return

    if (data._id) this._id = data._id
    if (data.cover) this.cover = data.cover
    if (data.suburbs) this.suburbs = data.suburbs
    if (data.surrounding) this.surrounding = data.surrounding
    if (data.priceFrom) this.priceFrom = data.priceFrom
    if (data.priceTo) this.priceTo = data.priceTo
    if (data.bedrooms) this.bedrooms = data.bedrooms
    if (data.bathrooms) this.bathrooms = data.bathrooms
    if (data.carspaces) this.carspaces = data.carspaces
    if (data.categories) this.categories = data.categories
    if (data.landarea) this.landarea = data.landarea
    if (data.floorarea) this.floorarea = data.floorarea
    if (data.filter) this.filter = data.filter
    if (data.saved) this.saved = data.saved
    this.loaded = true
  }
}
