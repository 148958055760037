'use client'

import CloseButton from '@component/closeButton'
import { hasPointingDevice } from '@utils'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useLockBodyScroll } from 'react-use'

export default function FullPopup({
  children,
  show = false,
  left = false,
  right = false,
  top = false,
  bottom = false,
  center = false,
  smCenter = false,
  closeable = false,
  maskCloseable = false,
  className,
  contentClassName,
  onClose,
  zIndex = 200,
  id,
  verifyPointingDevice = true
}) {

  const hasPointing = useMemo(() => hasPointingDevice(), [])
  useLockBodyScroll((!verifyPointingDevice || hasPointing) && show)



  return (
    <dialog className={
      classNames(
        'fixed left-0 top-0 w-full h-full  transition-opacity backdrop-blur-sm p-0 bg-transparent opacity-0 pointer-events-none',
        { '!opacity-100 !pointer-events-auto': show },
        className
      )
    }
      style={{ zIndex }}
      open={show}
      id={id}
    >
      <div className='bg-black opacity-50 w-full h-full' onClick={maskCloseable ? onClose : undefined}></div>
      <div className={
        classNames(
          'bg-white absolute delay-100 max-w-full',
          { 'transition-transform': left || right || top || bottom },
          { 'scale-x-0 left-0 top-0 h-full origin-left': left },
          { 'scale-x-100': show && left },
          { 'scale-x-0 right-0 top-0 h-full origin-right': right },
          { 'scale-x-100': show && right },
          { 'scale-y-0 left-0 top-0 w-full origin-top': top },
          { 'scale-y-100': show && top },
          { 'scale-y-0 left-0 top-0 w-full origin-bottom': bottom },
          { 'scale-y-100': show && bottom },
          { 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2': center },
          { 'left-0 top-0 sm:left-1/2 sm:top-1/2 sm:-translate-x-1/2 sm:-translate-y-1/2': smCenter },
          { 'pt-40 pb-10': closeable },
          contentClassName
        )
      }>
        {closeable && <CloseButton className='absolute right-10 top-10' onClick={onClose}></CloseButton>}
        {children}
      </div>
    </dialog>
  )
}