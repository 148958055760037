import IconLoading from '@icon/const/loading.svg?url'
import classNames from 'classnames'
import Image from 'next/image'

export default function MaskLoading({
  loading,
  transparent = false,
  className,
  size = 34
}) {

  return loading && (
    <div className={classNames(
      'absolute inset-x-0 inset-y-0 bg-white bg-opacity-80 flex items-center justify-center z-[1]',
      { '!bg-transparent pointer-events-none': transparent },
      className)}
    >
      <Image src={IconLoading} alt='loading' width={size} height={size} unoptimized></Image>
    </div>
  )
}